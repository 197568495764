import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { base, themed } from '../base';

const ImageWrapper = styled('img')(
  {
    display: 'block',
    maxWidth: '100%',
    height: 'auto'
  },
  base,
  themed('Image')
);

const Image = ({ src, alt, title, loading = 'lazy', ...props }) => (
  <ImageWrapper
    src={src}
    alt={alt}
    loading={loading}
    title={title || alt}
    {...props}
  />
);

export default Image;

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

Image.defaultProps = {
  m: 0
};
