import styled from 'styled-components';
// import FooterImage from './img/footer.svg';

const FooterWrapper = styled.section`
  padding: 120px 0 80px;
  ${'' /* background-image: url(${FooterImage}); */}
  background-repeat: no-repeat;
  background-position: 100% center;
  border-top: 1px solid ${({ theme }) => theme.colors.backgroundDark};
  overflow: hidden;
  ${'' /* background-color: ${({ theme }) => theme.colors.blue}; */}
  z-index: 1;
  @media (max-width: 1440px) {
    padding: 100px 0 50px;
  }
  @media (max-width: 990px) {
  }
  @media (max-width: 767px) {
  }

  h2,
  h3 {
    color: ${({ theme }) => theme.colors.textColor};
  }
  .imageWrapper {
    padding-bottom: 30px;
  }
  .language-select {
    padding-top: 30px;
    @media (max-width: 575px) {
      padding-top: 0px;
    }
  }
  .Language_search_select {
    max-width: 200px;
    @media (max-width: 575px) {
      height: 52px;
      margin-bottom: 20px;
    }
    @media (max-width: 480px) {
      height: 20px;
      margin-bottom: 0px;
    }
    .select__control,
    .select-field__wrapper {
      height: 100%;
    }
    .select__control {
      padding: 5px 8px;
      box-shadow: none;
      position: relative;
      ${'' /* border-color: transparent; */}
      ${'' /* background: transparent; */}
      @media (min-width: 576px) {
        ${'' /* border-color: transparent; */}
        ${'' /* border-left: 0; */}
        ${'' /* border-right: 0; */}
        border-radius: 5px;
        &:before {
          content: '';
          position: absolute;
          width: 1px;
          height: 55%;
          ${'' /* background: transparent; */}
          display: block;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }

      .select__placeholder {
        font-size: 16px;
        ${'' /* color: #fff; */}
        font-family: 'Poppins';
        font-weight: 400;
      }
      .select__indicator {
        ${'' /* color: #fff; */}
      }
      .select__value-container {
        padding: 0;
        .select__single-value {
          font-size: 16px;
          ${'' /* color: #fff; */}
          font-family: 'Poppins';
          font-weight: 400;
        }
      }
    }
    .select__indicator-separator {
      display: none;
    }
  }
  .appDownload {
    margin-top: 60px;
    margin-bottom: 30px;
  }
  .imageWrapper {
    display: flex;
    @media (max-width: 1200px) {
      flex-direction: column;
    }
    img {
      margin-right: 15px;
      width: 350px;
      @media (max-width: 1200px) {
        margin-bottom: 15px;
        margin-right: 0;
        width: 200px;
      }
    }
  }
`;

export const List = styled.ul``;

export const ListItem = styled.li`
  a {
    color: ${({ theme }) => theme.colors.labelColor};
    font-size: 14px;
    line-height: 36px;
    transition: all 0.2s ease;
    font-weight: 400;
    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.lightBlue};
      outline: 0;
      text-decoration: none;
    }
  }
  p {
    color: ${({ theme }) => theme.colors.labelColor};
    font-size: 14px;
    line-height: 36px;
    transition: all 0.2s ease;
    margin: 0;
  }
`;

export const SubFooterWrapper = styled.div`
  padding: 50px 0 30px;
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  .copyRight {
    margin-left: 0;
    margin-right: 0;
    width: calc(100% - 80px);
    @media (max-width: 768px) {
      width: calc(100% - 20px);
    }
    @media (max-width: 600px) {
      ${'' /* margin-top: 20px; */}
    }
    .copyRightText {
      font-size: 14px;
      font-family: 'Poppins';
      font-weight: 500;
      color: ${({ theme }) => theme.colors.textColor};
      a {
        color: ${({ theme }) => theme.colors.lightBlue};
      }
      @media (max-width: 480px) {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
    .footer_social {
      margin-left: auto;
      margin-top: -15px;
      @media (max-width: 600px) {
        margin-left: 0;
        margin-top: 15px;
      }
      a {
        color: ${({ theme }) => theme.colors.lightBlue};
        &:hover {
          opacity: 0.85;
        }
      }
    }
  }
`;

export default FooterWrapper;
