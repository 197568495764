import { lightenColor, darkenColor } from './utils/lightenDarken';
import { hexToRgbString } from './utils/colorUtils';

const base = {
  // black: '#000000', // 1
  // white: '#ffffff', // 2
  // textColor: '#424242', // 3
  // primary: '#FF7B00',
  // secondary: '#922b32',
  // // yellow: '#d1952a', // 12
  // yellow: '#fc0', // '#f5af33',
  // purple: '#212141',
  // muted: '#f2f2f2',
  // lightBlue: '#1A73E8'
  black: '#000000', // 1
  white: '#ffffff', // 2
  textColor: '#424242', // 3
  blue: '#0d2c54',
  green: '#72c02c',
  yellow: '#FFDE00', // '#f5af33',
  orange: '#ff5722',
  red: '#f44336',
  purple: '#673ab7',
  muted: '#f2f2f2',
  lightBlue: '#2273dc'
};

const layers = {
  baseLayer: 'rgba(0, 0, 0, 0.15)',
  normalLayer: 'rgba(0, 0, 0, 0.45)',
  darkLayer: 'rgba(0, 0, 0, 0.75)',
  primaryLayer: hexToRgbString(base.green, 0.6),
  lightBlueLayer: hexToRgbString(base.lightBlue, 0.6)
};

const colors = {
  ...base,
  ...layers,
  success: base.green,
  info: base.lightBlue,
  warning: base.yellow,
  danger: base.red,
  transparent: 'transparent', // 0
  black: base.black, // 1
  white: base.white, // 2
  background: lightenColor(base.textColor, 0.72), // #f9f9f9
  backgroundDark: lightenColor(base.textColor, 0.69), // #f9f9f9
  headingColor: lightenColor(base.blue, 0.1),
  textColor: base.textColor, // 3
  labelColor: '#767676', // 4
  inactiveField: base.muted, // 5
  inactiveButton: lightenColor(base.textColor, 0.8), // '#F9D592', //'#b7dbdd', // 6
  inactiveIcon: '#EBEBEB', // 7
  primary: base.yellow, // 8
  primaryHover: darkenColor(base.yellow, 0.1),
  secondary: base.yellow, // 10
  secondaryHover: darkenColor(base.yellow, 0.07),
  yellow: base.yellow, // 12
  yellowHover: darkenColor(base.yellow, 0.1),
  purple: base.purple,
  link: base.lightBlue,
  borderColor: '#1b1e25', // 14
  baseBoxShadow: `0px 8px 20px -6px ${hexToRgbString(base.black, 0.5)}`,
  primaryBoxShadow: `0px 8px 20px -6px ${hexToRgbString(base.yellow, 0.57)}`,
  secondaryBoxShadow: `0px 8px 20px -6px ${hexToRgbString(base.yellow, 0.5)}`,
  yellowBoxShadow: `0px 8px 20px -6px ${hexToRgbString(base.yellow, 0.5)}`,
  purpleBoxShadow: `0px 8px 20px -6px ${hexToRgbString(base.purple, 0.5)}`,
  greyBoxShadow: `0px 8px 20px -6px ${hexToRgbString(base.textColor, 0.5)}`,
  osram: '#f60',
  osramGrey: '#e6e7e8'
};

export default colors;
