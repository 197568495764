import styled from 'styled-components';

const NavBarWrapper = styled.div`
  .solar_navbar {
    background: ${props => props.background && props.theme.colors[props.background]};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.35s ease-in-out;
    ${'' /* transition: background 0.2s ease-in-out; */}
    padding: 30px 0;
    .logo-alt {
      display: none;
    }
    .main_menu {
      margin-right: 40px;
      li {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        a {
          padding: 10px 20px;
          ${'' /* font-size: 16px; */}
          ${'' /* font-weight: 400; */}
          color: #fff;
          line-height: 1;
          &:hover {
            ${'' /* color: #edcd37; */}
            color: ${({ theme }) => theme.colors.primary};
            ${'' /* border-bottom: 2px solid ${({ theme }) => theme.colors.primary}; */}
            background: none;
            border-radius: 0;
          }
          &.active {
              background: rgba(0, 0, 0, 0.2);
              border-radius: 20px;
            }
        }
        .nav-dropdown {
          .dropdown-group {
            top: 27px;
            padding-top: 20px;
            padding-bottom: 5px;
          }
          .dropdown-grid-group {
            transition: 0.35s ease-in-out;
            top: 71px;
            padding-top: 29px;
            a::before {
                content: none;
              }
          }
        }
      }
      @media (max-width: 990px) {
        display: none;
      }
    }
    .navbar_button {
      @media (max-width: 990px) {
        display: none;
      }
    }
    .reusecore-drawer__handler {
      @media (min-width: 991px) {
        display: none !important;
      }
      .hamburgMenu__bar {
        > span {
        }
      }
    }
  }


  &:hover {
    .solar_navbar {
      background: #fff;
      box-shadow: 0px 3px 8px 0px rgba(43, 83, 135, 0.08);
      .main-logo {
        display: none;
      }
      .logo-alt {
        display: block;
      }
      .main_menu {
        li {
          a {
            position: relative;
            color: #000;
            &:hover {
              color: ${({ theme }) => theme.colors.primary};
              ${'' /* border-bottom: 2px solid ${({ theme }) => theme.colors.primary}; */}
              background: none;
              border-radius: 0;
              &::before {
                content: '';
                height: 2px;
                width: 80%;
                margin: 0 10%;
                background: ${({ theme }) => theme.colors.primary};
                position: absolute;
                bottom: -31px;
                left: 0;
                transition: 0.35s ease-in-out;
              }
            }
            &.active {
              ${'' /* background: rgba(0, 0, 0, 0.2);
              border-radius: 20px; */}
              color: ${({ theme }) => theme.colors.primary};
              ${'' /* border-bottom: 2px solid ${({ theme }) => theme.colors.primary}; */}
              background: none;
              border-radius: 0;
              &::before {
                content: '';
                height: 2px;
                width: 80%;
                margin: 0 10%;
                background: ${({ theme }) => theme.colors.primary};
                position: absolute;
                bottom: -31px;
                left: 0;
                transition: 0.35s ease-in-out;
              }
            }
          }

          .nav-dropdown {
            .dropdown-group {
              top: 27px;
              padding-top: 20px;
              padding-bottom: 5px;
            }
            .dropdown-grid-group {
              transition: 0.35s ease-in-out;
              top: 71px;
              padding-top: 29px;
              a::before {
                content: none;
              }
            }
          }
        }
      }
    }
  }

  .sticky-nav-active {
    .solar_navbar {
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 3px 8px 0px rgba(43, 83, 135, 0.08);
      padding: 15px 0;
      .main-logo {
        display: none;
      }
      .logo-alt {
        display: block;
      }
      .main_menu {
        li {
          a {
            position: relative;
            color: #000;
            &:hover {
              color: ${({ theme }) => theme.colors.primary};
              &::before {
                content: '';
                height: 2px;
                width: 80%;
                margin: 0 10%;
                background: ${({ theme }) => theme.colors.primary};
                position: absolute;
                bottom: -16px;
                left: 0;
              }
            }
            &.active {
              ${'' /* background: rgba(0, 0, 0, 0.2);
              border-radius: 20px; */}
              color: ${({ theme }) => theme.colors.primary};
              ${'' /* border-bottom: 2px solid ${({ theme }) => theme.colors.primary}; */}
              background: none;
              border-radius: 0;
              &::before {
                content: '';
                height: 2px;
                width: 80%;
                margin: 0 10%;
                background: ${({ theme }) => theme.colors.primary};
                position: absolute;
                bottom: -16px;
                left: 0;
              }
            }
          }
          .nav-dropdown {
            .dropdown-group {
              top: 27px;
              padding-top: 12.5px;
              padding-bottom: 5px;
            }
            .dropdown-grid-group {
              transition: 0.35s ease-in-out;
              top: 56px;
              padding-top: 14px;
              a::before {
                content: none;
              }
            }
          }
        }
      }
    }
    .hamburgMenu__bar {
      > span {
        background: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export const NavWrapper = styled.div`
  ${'' /* background: red; */}
  .main_menu {
    li {
      ${'' /* display: inline;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    letter-spacing: 0.6px;
    line-height: 1.33333;
    text-transform: uppercase;
    color: #fff;
    margin: 0 4px; */}
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;

      ${'' /* a {
        &.active {
          background: rgba(0, 0, 0, 0.2);
          border-radius: 20px;
        }
      } */}
    }
  }
`;
export default NavBarWrapper;
