import React from 'react';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/source/contexts/DrawerContext';
// import Navbar from 'common/source/containers/Navbar';
import Navbar from './Nav';
import NavBarWrapper from './nav.style';

const SolarNavbar = props => {
  return (
    <NavBarWrapper>
      <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
        <DrawerProvider>
          <Navbar {...props} />
        </DrawerProvider>
      </Sticky>
    </NavBarWrapper>
  );
};

export default SolarNavbar;
