import React from 'react';
// import { Link } from 'gatsby';
import Icon from 'react-icons-kit';
import { socialDribbbleOutline } from 'react-icons-kit/ionicons/socialDribbbleOutline';
import { SocialProfileWrapper, SocialProfileItem } from './socialProfile.style';

const SocialProfile = ({ items, className, iconSize }) => {
  const addAllClasses = ['social_profiles'];

  if (className) {
    addAllClasses.push(className);
  }

  return (
    <SocialProfileWrapper className={addAllClasses.join(' ')}>
      {items.map(item => {
        const { icon, url, label } = item;
        return (
          <SocialProfileItem key={`social-item-${label}`} className="social_profile_item">
            <a
              href={url || '#'}
              aria-label={`SolarPower social ${label}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon
                icon={icon || socialDribbbleOutline}
                size={iconSize || 22}
                aria-hidden="true"
                alt={`SolarPower social ${label}`}
              />
              <span>{`SolarPower social ${label}`}</span>
            </a>
          </SocialProfileItem>
        );
      })}
    </SocialProfileWrapper>
  );
};

export default SocialProfile;
