import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import NavbarElement from 'reusecore/src/elements/Navbar';
import Drawer from 'reusecore/src/elements/Drawer';
import Button from 'reusecore/src/elements/Button';
import Logo from 'reusecore/src/elements/UI/Logo';
import Box from 'reusecore/src/elements/Box';
import HamburgMenu from '../../components/HamburgMenu';
import Container from '../../components/UI/Container';
// import ScrollSpyMenu from '../../components/ScrollSpyMenu';
import { DrawerContext } from '../../contexts/DrawerContext';
// import LogoImage from '../../assets/image/logo-white.png';
// import LogoImageAlt from '../../assets/image/logo.png';
import { NavWrapper } from './nav.style';
// import Dropdown from './Dropdown';
import DropdownGrid from './DropdownGrid';

const LogoImageWhite =
  'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1574193199/upsolar-cl/upsolar-logo_white_ouqfq8.png';
const LogoImageAlt =
  'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1574192504/upsolar-cl/upsolar_logo_ejlwmg.png';

const SolutionUtilitario =
  'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1573669486/upsolar-cl/solutions/search-and-rescue-helicopter-4532368_1920_pjl2lr.jpg';
const SolutionRural =
  'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1573663989/upsolar-cl/solutions/solar-835894_1920_vrk3zm.jpg';
const SolutionIndustria =
  'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1573667291/upsolar-cl/solutions/engineer-2558705_1920_yltbi8.jpg';
const SolutionParque =
  'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1568671583/solar-power-cl/soluciones/categorias/light-3779295_web_xet5kh.jpg';

const SolutionParticular =
  'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1568425729/solar-power-cl/soluciones/categorias/house-699978_web_plybqv.jpg';

const CatalogVial =
  'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1568428369/solar-power-cl/soluciones/categorias/alumbrado-publico-03_web_addxcs.jpg';
const CatalogPeatonal =
  'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1568428369/solar-power-cl/soluciones/categorias/alumbrado-publico-04_web_jalue3.jpg';
const CatalogOrnamental =
  'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1567818515/solar-power-cl/ornamental_c613l8.jpg';
const CatalogUtilitaria =
  'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1568427206/solar-power-cl/soluciones/categorias/perimetral-industrial-02_web_a5cz7n.jpg';
// export const MENU_ITEMS = [
//   {
//     label: 'Home',
//     path: '#banner_section',
//     offset: '0'
//   },
//   {
//     label: 'Feature',
//     path: '#feature_section',
//     offset: '0'
//   },
//   {
//     label: 'Pricing',
//     path: '#pricing_section',
//     offset: '0'
//   },
//   {
//     label: 'Testimonial',
//     path: '#testimonial_section',
//     offset: '0'
//   },
//   {
//     label: 'FAQ',
//     path: '#faq_section',
//     offset: '0'
//   }
// ];

const Navbar = ({
  navbarStyle,
  logoStyle,
  button,
  row,
  menuWrapper,
  background
}) => {
  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE'
    });
  };
  const backgroundColor = background ? { background } : {};

  return (
    <NavbarElement
      {...navbarStyle}
      className='solar_navbar'
      {...backgroundColor}
    >
      <NavWrapper>
        <Container>
          <Box {...row}>
            <Logo
              href='/'
              logoSrc={LogoImageWhite}
              title='Solar-Power logo'
              logoStyle={logoStyle}
              className='main-logo'
              aria-label='Solar-Power'
            />
            <Logo
              href='/'
              logoSrc={LogoImageAlt}
              title='Solar-Power logo'
              logoStyle={logoStyle}
              className='logo-alt'
              aria-label='Solar-Power'
            />
            <Box {...menuWrapper}>
              {/*
          <ScrollSpyMenu className="main_menu" menuItems={MENU_ITEMS} offset={-70} />
          */}
              <ul className='main_menu'>
                {/* <li>
                  <Link to="/" activeClassName="active">
                    Inicio
                  </Link>
                </li> */}
                <li>
                  <DropdownGrid
                    isOpen={false}
                    dropdownItems={[
                      {
                        cover: SolutionRural,
                        component: (
                          <Link to='/rural' activeClassName='active'>
                            <div className='solution-info'>
                              <h4>Para uso Rural</h4>
                            </div>
                          </Link>
                        )
                      },
                      {
                        cover: SolutionIndustria,
                        component: (
                          <Link to='/industrial' activeClassName='active'>
                            <div className='solution-info'>
                              <h4>Para la Industria</h4>
                            </div>
                          </Link>
                        )
                      },
                      {
                        cover: SolutionParticular,
                        component: (
                          <Link to='/particular' activeClassName='active'>
                            <div className='solution-info'>
                              <h4>Para uso Particular</h4>
                            </div>
                          </Link>
                        )
                      },
                      {
                        cover: SolutionUtilitario,
                        component: (
                          <Link to='/utilitario' activeClassName='active'>
                            <div className='solution-info'>
                              <h4>Para uso Utilitario</h4>
                            </div>
                          </Link>
                        )
                      }
                    ]}
                  >
                    <Link to='/soluciones' activeClassName='active'>
                      Soluciones
                    </Link>
                  </DropdownGrid>
                </li>

                <li>
                  <DropdownGrid
                    dropdownItems={[
                      {
                        cover: CatalogOrnamental,
                        component: (
                          <Link
                            to='/catalogo/portatil'
                            activeClassName='active'
                          >
                            <div className='solution-info'>
                              <h4>Energía Portátil</h4>
                            </div>
                          </Link>
                        )
                      },
                      {
                        cover: CatalogPeatonal,
                        component: (
                          <Link
                            to='/catalogo/domestico'
                            activeClassName='active'
                          >
                            <div className='solution-info'>
                              <h4>Energía Doméstica</h4>
                            </div>
                          </Link>
                        )
                      },
                      {
                        cover: CatalogUtilitaria,
                        component: (
                          <Link
                            to='/catalogo/industria'
                            activeClassName='active'
                          >
                            <div className='solution-info'>
                              <h4>Energía Industrial</h4>
                            </div>
                          </Link>
                        )
                      }
                    ]}
                  >
                    <Link to='/catalogo' activeClassName='active'>
                      Catalogo
                    </Link>
                  </DropdownGrid>
                </li>
                <li>
                  <a href='https://www.solar-power.cl/'>Iluminación Solar</a>
                </li>
              </ul>
              <Link to='/contacto' className='navbar_button'>
                <Button variant='hoverShadow' {...button} title='CONTACTO' />
              </Link>
              <Drawer
                width='420px'
                placement='right'
                drawerHandler={<HamburgMenu barColor='#fff' />}
                open={state.isOpen}
                toggleHandler={toggleHandler}
              >
                {/*
          <ScrollSpyMenu
            className="mobile_menu"
            menuItems={MENU_ITEMS}
            drawerClose
            offset={-100}
          />
            */}

                <ul className='mobile_menu'>
                  <li>
                    <Link to='/'>Inicio</Link>
                  </li>
                  <li>
                    <Link to='/soluciones'>Soluciones</Link>
                  </li>
                  <li>
                    <Link to='/catalogo'>Catálogo</Link>
                  </li>
                  <li>
                    <a href='https://www.solar-power.cl/'>Iluminación Solar</a>
                  </li>
                </ul>
                <Link to='/contacto' className='navbar_drawer_button'>
                  <Button variant='hoverShadow' {...button} title='CONTACTO' />
                </Link>
              </Drawer>
            </Box>
          </Box>
        </Container>
      </NavWrapper>
    </NavbarElement>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
    display: 'block'
  },
  row: {
    flexBox: true,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  logoStyle: {
    maxWidth: ['200px', '235px'],
    pl: '20px',
    pr: '20px'
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'primaryBoxShadow',
    // colors: 'primaryWithBg',
    minHeight: 'auto',
    height: '40px'
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center'
  }
};

export default Navbar;
