import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { socialTwitter } from 'react-icons-kit/ionicons/socialTwitter';
import { socialFacebook } from 'react-icons-kit/ionicons/socialFacebook';
import { socialInstagram } from 'react-icons-kit/ionicons/socialInstagram';
import { androidMail } from 'react-icons-kit/ionicons/androidMail';
// import { socialGithub } from 'react-icons-kit/ionicons/socialGithub';
// import { socialGoogleplusOutline } from 'react-icons-kit/ionicons/socialGoogleplusOutline';

import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
import Heading from 'reusecore/src/elements/Heading';
// import Select from 'reusecore/src/elements/Select';
import Container from '../../components/UI/Container';
import SocialProfile from '../SocialProfile';
import FooterWrapper, {
  SubFooterWrapper,
  List,
  ListItem
} from './footer.style';

// import LogoWhite from '../../assets/image/logo-white.png';
// import PlaystoreImage from '../../../assets/image/ride/footerplay.svg';

// const LogoWhite =
//   'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1566581424/solar-power-cl/SolarPower_White_qpfzey.png';

const Logo =
  'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1574192504/upsolar-cl/upsolar_complete_gsx42z.png';

export const LanguageNAMES = [
  {
    label: 'Spanish',
    value: 'es'
  },
  {
    label: 'English',
    value: 'eng'
  }
];

export const SOCIAL_PROFILES = [
  {
    icon: androidMail,
    url: 'mailto:contacto@upsolar.cl',
    label: 'email'
  },
  {
    icon: socialInstagram,
    url: 'https://www.instagram.com/solarpowerchile',
    label: 'instagram'
  },
  {
    icon: socialFacebook,
    url: 'https://www.facebook.com/SolarPowerIluminacion',
    label: 'facebook'
  },
  {
    icon: socialTwitter,
    url: 'https://twitter.com/solarpower_cl',
    label: 'twitter'
  }
  // {
  //   icon: socialGithub,
  //   url: '#'
  // },
  // {
  //   icon: socialGoogleplusOutline,
  //   url: '#'
  // }
];

const Footer = ({ row, col, colOne, colTwo, titleStyle }) => {
  return (
    <Fragment>
      <FooterWrapper id='footerSection'>
        <Container noGutter mobileGutter width='1200px'>
          <Box className='row' {...row}>
            <Box {...colOne}>
              <Box className='imageWrapper'>
                <Link to='/'>
                  <Image src={Logo} alt='UpSolar logo' />
                </Link>
              </Box>
              {/* <Box className="language-select">
                <Heading content="Language" {...titleStyle} />
                <Select
                  options={LanguageNAMES}
                  placeholder="Spanish"
                  className="Language_search_select"
                  aria-label="Language_search_input"
                />
              </Box> */}
            </Box>
            <Box {...colTwo}>
              <Box className='col' {...col}>
                <Heading as='h3' content='Direcciones Útiles' {...titleStyle} />
                <List>
                  <ListItem>
                    <Link to='/' className='ListItem'>
                      Inicio
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link to='/soluciones' className='ListItem'>
                      Soluciones
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link to='/catalogo' className='ListItem'>
                      Catalogo Productos
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link to='/paneles-solares' className='ListItem'>
                      Paneles solares
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link to='/mapa-solar' className='ListItem'>
                      Mapa Solar
                    </Link>
                  </ListItem>
                  <ListItem>
                    <a
                      href='https://consumo.solar-power.cl/'
                      className='ListItem'
                    >
                      Calculadora Energética
                    </a>
                  </ListItem>
                </List>
              </Box>
              <Box className='col' {...col}>
                <Heading as='h3' content='Nosotros' {...titleStyle} />
                <List>
                  <ListItem>
                    <Link to='/recursos' className='ListItem'>
                      Blog & Recursos
                    </Link>
                  </ListItem>
                  {/* <ListItem>
                    <Link to="/preguntas-frecuentes" className="ListItem">
                      Preguntas frecuentes
                    </Link>
                  </ListItem> */}
                  <ListItem>
                    <Link to='/quienes-somos' className='ListItem'>
                      Quiénes somos
                    </Link>
                  </ListItem>
                  {/* <ListItem>
                    <Link to="/politicas-de-garantia" className="ListItem">
                      Políticas de Garantía
                    </Link>
                  </ListItem> */}
                  <ListItem>
                    <a href='https://www.solar-power.cl/' className='ListItem'>
                      Iluminación Solar
                    </a>
                  </ListItem>
                </List>
              </Box>
              <Box className='col' {...col}>
                <Heading as='h3' content='Información' {...titleStyle} />
                <List>
                  <ListItem>
                    <Link to='/contacto' className='ListItem'>
                      Contacto
                    </Link>
                  </ListItem>
                  <ListItem>
                    <p className='ListItem'>Fono: +56 (2) 3267 8448</p>
                  </ListItem>
                  <ListItem>
                    <p className='ListItem'>
                      UPSOLAR es una empresa del holding FADILUK®.
                    </p>
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Box>
        </Container>
      </FooterWrapper>
      <SubFooterWrapper>
        <Container noGutter mobileGutter width='1200px'>
          <Box className='row copyRight' {...row}>
            <Text
              content={
                <span>
                  Una empresa del grupo{' '}
                  <a
                    href='https://www.fadiluk.cl'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Fadiluk®
                  </a>{' '}
                  - Desarrollado por{' '}
                  <a
                    href='https://www.chamlabs.com'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Chamlabs ◎_⦿
                  </a>
                </span>
              }
              className='copyRightText'
            />
            <SocialProfile
              className='footer_social'
              items={SOCIAL_PROFILES}
              iconSize={18}
            />
          </Box>
        </Container>
      </SubFooterWrapper>
    </Fragment>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-4px',
    mr: '-4px'
  },
  // Footer col one style
  colOne: {
    width: ['100%', '30%', '33%', '33%'],
    mb: ['30px', 0],
    pl: ['0px', 0],
    pr: ['0px', '0px', 0]
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '70%', '67%', '67%'],
    flexBox: true,
    flexWrap: 'wrap'
  },
  // Footer col default style
  col: {
    width: ['100%', 1 / 3, 1 / 3, 1 / 3],
    pl: [0, '15px'],
    pr: [0, '15px'],
    mb: ['30px', '30px']
  },
  // widget title default style
  titleStyle: {
    // color: '#FFFFFF',
    fontSize: ['15px', '16px', '16px', '18px', '18px'],
    fontWeight: '600',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '30px'],
    fontFamily: 'Poppins'
  },
  // Default logo size
  // logoStyle: {
  //   width: '128px',
  //   mb: '15px'
  // },
  // widget text default style
  textStyle: {
    // color: '#FFFFFF',
    fontSize: '16px',
    mb: '12px',
    fontWeight: '600',
    fontFamily: 'Poppins'
  }
};

export default Footer;
