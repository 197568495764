const config = {
  siteTitle: 'UpSolar – Energía Solar Autónoma', // Site title.
  siteTitleShort: 'UpSolar', // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: 'UpSolar', // Alternative site title for SEO.
  siteLogo:
    'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1574192504/upsolar-cl/upsolar_logo_ejlwmg.png', // '/logos/logo-512x512.png', // Logo used for SEO and manifest.
  siteAuthor: 'Diego Cortez',
  contentFolder: 'content',
  siteUrl: 'https://www.upsolar.cl', // Domain of your website without pathPrefix.
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription:
    'Autónoma – Energía Solar Autónoma. Proyectos y Productos profesionales, por un equipo Profesional', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml', // Path to the RSS file.
  siteFBAppID: '', // FB Application ID for using app insights
  FBPageURL: 'https://www.facebook.com/SolarPowerIluminacion',
  FBPageSlug: 'SolarPowerIluminacion',
  googleAnalyticsID: 'UA-142738945-3', // GA tracking ID.
  googleTagManagerID: 'GTM-KQKQFXK', // google tag manager ID
  googleReCaptchaSiteKey: '6Ldzv78UAAAAAF_srITv1P6qfNri9iebKadrJ5kh',
  postDefaultCategoryID: 'Energy', // Default category for posts.
  dateFromFormat: 'YYYY-MM-DD', // Date format used in the frontmatter.
  dateFormat: 'DD/MM/YYYY', // Date format for display.
  userName: 'UpSolar', // Username to display in the author segment.
  userEmail: 'contacto@upsolar.cl', // Email used for RSS feed's author segment
  userTwitter: '@solarpower_cl', // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: 'Santiago, Chile', // User location to display in the author segment.
  userAvatar: 'https://api.adorable.io/avatars/150/test.png', // User avatar to display in the author segment.
  userDescription: 'UpSolar – Energía Solar Autónoma', // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [
    {
      label: 'Instagram',
      url: 'https://www.instagram.com/solarpowerchile',
      iconClassName: 'fa fa-instagram'
    },
    {
      label: 'Twitter',
      url: 'https://twitter.com/solarpower_cl',
      iconClassName: 'fa fa-twitter'
    },
    {
      label: 'Email',
      url: 'mailto:contacto@upsolar.cl',
      iconClassName: 'fa fa-envelope'
    }
  ],
  copyright: 'UpSolar, 2019. Una empresa Fadiluk ©', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#FFDE00', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff' // Used for setting manifest background color.
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === '/') {
  config.pathPrefix = '';
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, '')}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === '/')
  config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== '/')
  config.siteRss = `/${config.siteRss}`;

module.exports = config;
