import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';

const DropdownWrapper = styled.div`
  position: relative;
  .dropdown-grid-enter {
    opacity: 0;
    ${'' /* transform: scale(0.9, 0.9); */}
    transform: rotateX(-15deg);
  }
  .dropdown-grid-enter-active {
    opacity: 1;
    transform: rotateX(0);
    ${'' /* transform: translateX(0); */}
    ${'' /* transform: translateY(0); */}
    transition: opacity 200ms, transform 200ms;
  }
  .dropdown-grid-exit {
    opacity: 1;
  }
  .dropdown-grid-exit-active {
    opacity: 0;
    transform: rotateX(-15deg);
    ${'' /* transform: scale(0.9, 0.9); */}
    ${'' /* transition: opacity 100ms, transform 100ms; */}
    transition: opacity 200ms, transform 200ms;
  }
`;
const DropdownGroup = styled.div`
  z-index: 1;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  position: fixed;
  width: 100%;
  min-height: 250px;
  left: 0;
  ${'' /* background: white; */}
  transition: 0.15s all;
  opacity: 0;
  height: 0px;
  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      height: inherit;
    `}
`;

const DropdownItem = styled.div`
  display: grid;
  position: relative;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  color: white;
  z-index: 1;
  .solution-info {
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    ${({ fullWidth }) =>
      fullWidth &&
      css`
        max-width: 100%;
      `}
    h4 {
      color: ${({ theme }) => theme.colors.white};
      ${'' /* font-size: 30px; */}
      line-height: 35px;
      font-weight: 900;
      margin: 0;
      margin-bottom: 5px;
    }
    p {
      color: ${({ theme }) => theme.colors.white};
      margin-bottom: 10px;
      font-size: 1rem;
      line-height: 20px;
      font-weight: 300;
    }
  }
  ${({ cover }) =>
    cover &&
    css`
      background-image: url(${cover});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      &:before {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        background-color: ${({ theme }) => theme.colors.baseLayer};
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        transition: 0.3s all;
      }
      &:hover {
        :before {
          background-color: ${({ theme }) => theme.colors.lightBlueLayer};
        }
    `}
`;

const BackgroundMask = styled.div`
  .background-layer-enter {
    opacity: 0;
  }
  .background-layer-enter-active {
    opacity: 1;
    transition: all 200ms;
  }
  .background-layer-exit {
    opacity: 1;
  }
  .background-layer-exit-active {
    opacity: 0;
    transition: all 200ms;
  }
  .menu-layer {
    position: absolute;
    left: 0;
    top: 0;
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      background: rgba(0, 0, 0, 0.4);
      height: 100vh;
      width: 100vw;
      top: 100px;
      left: 0;
      opacity: 1;
      transition: all 0.2s;
      overflow: hidden;
    }
  }
`;

class DropdownGridMenu extends React.Component {
  state = { open: this.props.isOpen || false };
  handleOpen = () => this.setState({ open: true });
  handleClose = () => this.setState({ open: false });

  render() {
    const { open } = this.state;
    const { children, content, dropdownItems } = this.props;
    return (
      <Fragment>
        <BackgroundMask>
          <CSSTransition in={open} timeout={200} classNames="background-layer" unmountOnExit>
            <div className="menu-layer" />
          </CSSTransition>
        </BackgroundMask>
        <DropdownWrapper
          className="nav-dropdown"
          onMouseEnter={this.handleOpen}
          onMouseLeave={this.handleClose}
        >
          <span>{children || content}</span>
          <CSSTransition in={open} timeout={200} classNames="dropdown-grid" unmountOnExit>
            <DropdownGroup className="dropdown-grid-group" isOpen>
              {dropdownItems &&
                dropdownItems.map((item, index) => {
                  return (
                    <DropdownItem cover={item.cover} className="dropdown-grid-item" key={index}>
                      {item.component}
                    </DropdownItem>
                  );
                })}
            </DropdownGroup>
          </CSSTransition>
        </DropdownWrapper>
      </Fragment>
    );
  }
}

export default DropdownGridMenu;
